import React from "react"
import { Link, graphql } from "gatsby"

import Styled from "styled-components"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentBlock from "../components/contentBlock"
import TagBar from "../components/tagBar"
import BreadcrumbsBar from "../components/breadcrumbsBar"
import Breadcrumbs from "../components/breadcrumbs"

import { rhythm } from "../utils/typography"
import { colors } from "../utils/colors"

const Article = Styled.article`
  max-width: ${rhythm(30)};
  margin: auto;
  padding-left: ${rhythm(1)};
  padding-right: ${rhythm(1)};
`
const Divider = Styled.hr`
  margin-bottom: ${rhythm(1)};
`
const Links = Styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${rhythm(0.5)} 0;
`
const PrevLink = Styled(Link)`
  padding: ${rhythm(0.05)} ${rhythm(0.3)};
  margin: ${rhythm(0.2)} ${rhythm(0.5)};
  box-shadow: none;
  border-radius: ${rhythm(0.2)};
  border: 1px solid ${colors.gray};
  color: ${colors.darkGray};
  transition: all 0.2s ease-in;
  &:hover {
    color: black;
    border: 1px solid black;
  }
`
const NextLink = Styled(Link)`
  padding: ${rhythm(0.05)} ${rhythm(0.3)};
  margin: ${rhythm(0.2)} ${rhythm(0.5)};
  box-shadow: none;
  border-radius: ${rhythm(0.2)};
  border: 1px solid ${colors.gray};
  color: ${colors.darkGray};
  transition: all 0.2s ease-in;
  &:hover {
    color: black;
    border: 1px solid black;
  }
`

class PortfolioPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <ContentBlock>
          <Article>
            <header>
              <BreadcrumbsBar>
                <Breadcrumbs name="FeedYourWeb" page="/" color={colors.gray} />
                <Breadcrumbs name="Portfolio" page="/portfolio" color={colors.gray} />
                <Breadcrumbs name={post.frontmatter.title} page="" color={colors.gray} />
                {/* <Breadcrumbs name={post.frontmatter.title} page={this.props.location.pathname} color="#666" /> */}
              </BreadcrumbsBar>
              <h1>
                {post.frontmatter.title}
              </h1>
              <TagBar tags={post.frontmatter.tags} small />
            </header>
            <section dangerouslySetInnerHTML={{ __html: post.html }} />
          </Article>
        </ContentBlock>

        <ContentBlock>
          <TagBar tags={post.frontmatter.tags} />
        </ContentBlock>

        <ContentBlock>
          <footer>
            <Bio />
          </footer>

          <Divider />

          <Links>
            {previous && (<PrevLink to={previous.fields.slug} rel="prev">← {previous.frontmatter.title}</PrevLink>)}
            {next && (<NextLink to={next.fields.slug} rel="next">{next.frontmatter.title} →</NextLink>)}
          </Links>

        </ContentBlock>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query PortfolioPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      rawMarkdownBody
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`

export default PortfolioPostTemplate;
